import Modal from 'react-modal'
import {modalDefaultStyles} from '../../../App'
import {ModalButton, ModalControls, ModalHR, ModalTitle,} from '../../common/ModalParts'
import React, {useState} from 'react'
import {Checkbox, ControlLabel, ModalBody} from 'react-bootstrap'
import {Select} from '../../form-stuff'
import {day} from '../../../Modules/formatters/date'
import {Textarea} from "../../form-stuff/Textarea";

export const ChangeBreakfastModal = ({
                                         isOpen,
                                         hideModal,
                                         breakfasts,
                                         save,
                                         editData: {
                                             breakfast: breakfast_pk,
                                             delivery_date,
                                             qty,
                                             note,
                                             is_complementary
                                         },
                                         availableDates,
                                     }) => {
    const [selectedData, setSelectedData] = useState(breakfast_pk)
    const [selectedDate, setSelectedDate] = useState(delivery_date)
    const [changeNote, setChangeNote] = useState(note)

    const saveHandler = () => {
        save({pk: selectedData, date: selectedDate, note: changeNote})
        hideModal()
    }
    return (
        <Modal
            isOpen={isOpen}
            defaultStyles={{
                ...modalDefaultStyles,
                content: {
                    ...modalDefaultStyles.content,
                    width: 900,
                    maxWidth: 900,
                },
            }}
        >
            <ModalTitle>{availableDates ? 'Изменить завтрак' : 'Изменить тип завтрака'}</ModalTitle>
            <ModalHR/>
            <ModalBody>
                <Select
                    label="Тип Завтрака"
                    defaultOption={selectedData}
                    options={breakfasts}
                    value={selectedData}
                    onChange={setSelectedData}
                />
                {availableDates ? <Select
                        value={selectedDate}
                        options={availableDates}
                        onChange={(date) => {
                            setSelectedDate(date)
                        }}
                        noMargin
                    /> :
                    <ControlLabel>
                        Дата доставки: {day(delivery_date)}
                    </ControlLabel>
                }
                <div>
                    <Checkbox
                        type="checkbox"
                        value={is_complementary}
                        checked={is_complementary}
                        disabled
                    >
                        Комплиментарный завтрак
                    </Checkbox>
                </div>

                <Textarea
                    label="Примечание"
                    value={changeNote}
                    onChange={setChangeNote}
                />

                <ControlLabel>Колчество: {qty}</ControlLabel>
            </ModalBody>
            <ModalControls>
                <ModalButton
                    bsStyle="success"
                    onClick={saveHandler}
                >
                    Сохранить
                </ModalButton>
                <ModalButton
                    bsStyle="danger"
                    onClick={hideModal}
                >
                    Отмена
                </ModalButton>
            </ModalControls>
        </Modal>
    )
}
