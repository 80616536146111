import React from 'react'
import moment from 'moment'
import {
    CashIcon,
    CardIcon,
    SellIcon,
    RefundIcon, BankIcon,
} from '../../../../ui/icons/shopping'
import { BlockWithIcon } from '../HelpStyles/BlockWithIcon'
import { Text } from '../HelpStyles/Text'
import paymentTypes from '../../../../constants/paymentTypes'
import { rub } from '../../../../../../Modules/formatters/currency'

export const getTypeString = isRefund => {
  if (isRefund)
    return (
      <BlockWithIcon color="checksTableFailure">
        <RefundIcon />
        <Text>Возврат</Text>
      </BlockWithIcon>
    )

  return (
    <BlockWithIcon color="checksTableSuccess">
      <SellIcon />
      <Text>Продажа</Text>
    </BlockWithIcon>
  )
}

export const getTimeString = date => moment(date).format('HH:mm')

export const getPaymentTypeString = paymentType => {
  switch (paymentType) {
    case paymentTypes.cash:
      return (
        <BlockWithIcon>
          <CashIcon />
          <Text>{paymentTypes.cash}</Text>
        </BlockWithIcon>
      )

    case paymentTypes.card:
      return (
        <BlockWithIcon>
          <CardIcon />
          <Text>{paymentTypes.card}</Text>
        </BlockWithIcon>
      )

      case paymentTypes.bank:
          return (
              <BlockWithIcon>
                  <BankIcon />
                  <Text>{paymentTypes.bank}</Text>
              </BlockWithIcon>
          )

    default:
      return paymentType
  }
}

export const getTotalString = {
  forCheck: total => rub(total),
  forRefund: total => rub(total / 100),
}
