import * as types from './types'

const initialState = {
    daysInPast: 5,
    daysInFuture: 10,
    gridScale: 100,

    numberOfBathrooms: 1,
    isWashingOperationsOn: false,
    numberOfWashingMachines: 1,
    bedsChangePeriod: 3,

    legalAddress: null,
    legalName: null,
    cashier: '',
    inn: null,

    printerIsOn: true,
    isLoyaltyEnabled: false,
    sendReceiptToEmail: false,
    checksV2Enabled: false,
    isTodoListEnabled: false,
    isDebugMenuEnabled: false,

    isCloudGhotelOn: false,
    isMaidsBonusesEnabled: false,
    isUsersManagementV2Enabled: false,
    isMaidEnable: false,

    isLoading: true,
    isLoaded: false,
    isFailed: false,
    gridDate: null,

    doorLocks: null,
    hsuDoorLocksApiKey: null,
    hsuDoorLocksUrlServer: null,
    isWubookUpdatingModifiedPricesEnabled: false,
    isManualNewBookingsFetchEnabled: false,
    disableBannerPrintCleaningSheet: false,
    isLockEnabled: false,
    breakfastLimitTime: '20:00:00',

    isRoomStatusesEnabled: false,
    makeRoomsDirtyTime: false,
    isChecklistEnabled: false,

    isDepositEnabled: false,
    depositAmount: 0,
}

export default function reducer(state = initialState, action) {
    const {payload} = action

    switch (action.type) {
        case types.SETTINGS_LOADED:
            return {
                ...state,
                daysInFuture: Number(payload.daysInFuture),
                daysInPast: Number(payload.daysInPast),
                printerIsOn: payload.printerIsOn,
                inn: payload.inn,
                legalAddress: payload.legalAddress,
                legalName: payload.legalName,
                cashier: payload.cashier,
                numberOfBathrooms: payload.numberOfBathrooms,

                isWashingOperationsOn: payload.isWashingOperationsOn,
                numberOfWashingMachines: payload.numberOfWashingMachines,

                sendReceiptToEmail: payload.sendReceiptToEmail,
                checksV2Enabled: payload.checksV2Enabled,
                isLoyaltyEnabled: payload.isLoyaltyEnabled,
                isCloudGhotelOn: payload.isCloudGhotelOn,
                isMaidsBonusesEnabled: payload.isMaidsBonusesEnabled,
                isUsersManagementV2Enabled: payload.isUsersManagementV2Enabled,
                isTodoListEnabled: payload.isTodoListEnabled,
                isDebugMenuEnabled: payload.isDebugMenuEnabled,
                isMaidEnable: payload.isMaidEnable,

                isLoading: false,
                isLoaded: true,

                bedsChangePeriod: payload.bedsChangePeriod,
                gridScale: payload.gridScale,

                doorLocks: payload.doorLocks,
                hsuDoorLocksApiKey: payload.hsuDoorLocksApiKey,
                hsuDoorLocksUrlServer: payload.hsuDoorLocksUrlServer,
                isWubookUpdatingModifiedPricesEnabled: payload.isWubookUpdatingModifiedPricesEnabled,
                isManualNewBookingsFetchEnabled: payload.isManualNewBookingsFetchEnabled,
                disableBannerPrintCleaningSheet: payload.disableBannerPrintCleaningSheet,
                isLockEnabled: payload.isLockEnabled,
                breakfastLimitTime: payload.breakfastLimitTime,

                isRoomStatusesEnabled: payload.isRoomStatusesEnabled,
                makeRoomsDirtyTime: payload.makeRoomsDirtyTime,
                isChecklistEnabled: payload.isChecklistEnabled,
                isDepositEnabled: payload.isDepositEnabled,
                depositAmount: payload.depositAmount,
            }

        case types.UPDATE_SETTINGS:
            return {
                ...state,
                daysInFuture: Number(payload.newDaysInFuture),
                daysInPast: Number(payload.newDaysInPast),
                gridScale: payload.gridScale
            }

        case types.SET_GRID_DATE:
            return {
                ...state,
                gridDate: payload,
            }

        case types.RESET_GRID_DATE:
            return {
                ...state,
                gridDate: null,
            }

        case types.SET_GRID_SCALE:
            return {
                ...state,
                gridScale: payload,
            }

        case types.LOCAL_SETTINGS:
            return {
                ...state,
                sendReceiptToEmail: payload.sendReceiptToEmail,
                checksV2Enabled: payload.checksV2Enabled,
                printerIsOn: payload.printerIsOn,
                isLoyaltyEnabled: payload.isLoyaltyEnabled || false,
                isCloudGhotelOn: payload.isCloudGhotelOn,
                isMaidsBonusesEnabled: payload.isMaidsBonusesEnabled,
                isUsersManagementV2Enabled: payload.isUsersManagementV2Enabled,
                isTodoListEnabled: payload.isTodoListEnabled,
                isDebugMenuEnabled: payload.isDebugMenuEnabled,
                isLockEnabled: payload.isLockEnabled,
                breakfastLimitTime: payload.breakfastLimitTime
            }

        default:
            return state
    }
}
