import {doorLocksTypes, loadingFinished} from './utils'
import {
    getCCFlags,
    getHotelCCFlags,
    getSettingsCleaning,
    getSettingsLegal,
    getSettingsUI,
    updateSettingsCall
} from '../api/ccAPI/requests'
import * as types from './types'
import Cookies from 'js-cookie'
import {getExpiredTime, saveExpiredTime} from "../../Components/DayTotals/helpers/CookiesAPI/changers";

export const loadSettings = () => async (dispatch, getState) => {
    const {session} = getState()
    const lcode = session.lcode

    const uiSettings = await geUI(lcode)
    const cleaningSettings = await getCleaning(lcode)
    const legalSettings = await getLegal(lcode)
    const ccFlags = await getCC()
    const hotelCCFlags = await getHotelCC(lcode)

    let fillCheckListExpiredDate = getExpiredTime()
    if (!fillCheckListExpiredDate) {
        saveExpiredTime(hotelCCFlags.timeToCommitCheckLists)
    }

    dispatch({
        type: types.SETTINGS_LOADED,
        payload: {
            ...uiSettings,
            ...cleaningSettings,
            ...legalSettings,
            ...ccFlags,
            ...hotelCCFlags,
        },
    })

    loadingFinished()
}

const geUI = async lcode => {
    const uiSettings = await getSettingsUI(lcode)
    if (uiSettings) {
        return {
            daysInFuture: uiSettings.days_in_grid,
            daysInPast: uiSettings.days_in_past,
            gridScale: uiSettings.grid_scale
        }
    }
}

const getCleaning = async lcode => {
    const clean = await getSettingsCleaning(lcode)
    if (clean) {
        return {
            bedsChangePeriod: clean.beds_change_period,
            numberOfBathrooms: clean.bathrooms_number,
            isWashingOperationsOn: clean.is_washing_operations_on,
            numberOfWashingMachines: clean.number_of_washing_machines,
        }
    }
}

const getLegal = async lcode => {
    const legalSettings = await getSettingsLegal(lcode)
    if (legalSettings) {
        return {
            legalAddress: legalSettings.legal_address,
            legalName: legalSettings.legal_name,
            cashier: legalSettings.cashier,
            inn: legalSettings.inn,
        }
    }
}

const getCC = async () => {
    const localSettings = Cookies.get(types.LOCAL_SETTINGS)
    let ccFlags = await getCCFlags()
    if (ccFlags.is_debug_menu_enabled && localSettings) {
        ccFlags = JSON.parse(localSettings)
        return {...ccFlags, isDebugMenuEnabled: true}
    } else return {
        sendReceiptToEmail: ccFlags.is_e_checks_enabled,
        checksV2Enabled: ccFlags.is_checks_v2_enabled,
        printerIsOn: ccFlags.is_checks_printer_enabled,
        isLoyaltyEnabled: ccFlags.is_ghotel_loyalty_enabled,
        isTodoListEnabled: ccFlags.is_todo_list_enabled,
        isCloudGhotelOn: ccFlags.is_cloud_ghotel_on,
        isMaidsBonusesEnabled: ccFlags.is_maids_bonuses_enabled,
        isUsersManagementV2Enabled: ccFlags.is_users_management_v2_enabled,
        isDebugMenuEnabled: ccFlags.is_debug_menu_enabled,
        isLockEnabled: ccFlags.is_lock_enabled,
        isMaidEnable: ccFlags.is_maid_enable,
    }
}

const getHotelCC = async lcode => {
    return await getHotelCCFlags(lcode).then(it => {
        const {
            door_locks: doorLocks,
            hsu_door_locks_api_key: hsuDoorLocksApiKey,
            hsu_door_locks_url_server: hsuDoorLocksUrlServer,
            is_wubook_calculating_modified_prices_enabled: isWubookUpdatingModifiedPricesEnabled,
            is_manual_new_bookings_fetch_enabled: isManualNewBookingsFetchEnabled,
            disable_banner_print_cleaning_sheet: disableBannerPrintCleaningSheet,
            breakfast_limit_time: breakfastLimitTime,
            is_room_statuses_enabled: isRoomStatusesEnabled,
            make_rooms_dirty_time: makeRoomsDirtyTime,
            is_checklist_enabled: isChecklistEnabled,
            is_deposit_enabled: isDepositEnabled,
            deposit_amount: depositAmount,
            checklist_minutes_limit: timeToCommitCheckLists
        } = it


        return {
            doorLocks,
            isWubookUpdatingModifiedPricesEnabled,
            isManualNewBookingsFetchEnabled,
            disableBannerPrintCleaningSheet,
            hsuDoorLocksApiKey,
            hsuDoorLocksUrlServer,
            breakfastLimitTime,
            isRoomStatusesEnabled,
            makeRoomsDirtyTime,
            isChecklistEnabled,
            isDepositEnabled,
            depositAmount,
            timeToCommitCheckLists
        }
    })
        .catch(_ => {
            return {doorLocks: doorLocksTypes.v1}
        })

}

export const updateSettings = (
    newDaysInFuture,
    newDaysInPast,
    gridScale,
) => async (dispatch, getState) => {
    const {session} = getState()
    const data = {
        days_in_grid: newDaysInFuture,
        days_in_past: newDaysInPast,
        grid_scale: gridScale,
    }
    await updateSettingsCall(session.lcode, data)

    dispatch({
        type: types.UPDATE_SETTINGS,
        payload: {
            newDaysInFuture,
            newDaysInPast,
            gridScale,
        },
    })
}

export const setGridDate = date => ({
    type: types.SET_GRID_DATE,
    payload: date,
})

export const resetGridDate = () => ({
    type: types.RESET_GRID_DATE,
})

export const setGridScale = scale => async (dispatch, getState) => {
    const {settings, session} = getState()
    const {newDaysInFuture, newDaysInPast} = settings
    const data = {
        days_in_grid: newDaysInFuture,
        days_in_past: newDaysInPast,
        grid_scale: scale,
    }
    await updateSettingsCall(session.lcode, data)
    dispatch({
        type: types.SET_GRID_SCALE,
        payload: scale,
    })
}

export const setCCflags = settings => (dispatch) => {
    Cookies.set(types.LOCAL_SETTINGS, settings)
    dispatch({
        type: types.LOCAL_SETTINGS,
        payload: settings,
    })
}
