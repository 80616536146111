import styled from 'styled-components'

export const Page = styled.div`
    font-family: "Times New Roman",serif;
    font-size: 12pt;
`

export const Box = styled.div`
  display: flex;
  page-break-inside: avoid;
  justify-content: space-between;
`

export const Field = styled.div`
    display: flex;
    flex-wrap: wrap;
    page-break-inside: avoid;
    font-style: ${props => props.italic && 'italic'};
    width: ${props => props.width ? props.width : "auto"};
`

export const Title = styled.div`
    margin: auto;
    font-weight: bold;
    text-align: center;
`

export const Name = styled.span`
  display: inline-block;
  margin-right: 2mm;
  font-weight: ${props => props.bold && 'bold'};
`

export const Date = styled.div`
    font-size: 12pt;
    text-align: right;
    padding-left: 43%;
    margin-bottom: 4mm;

`
export const Text = styled.div`
    position: relative;
    display: inline-block;
    line-height: 1.2;
    border-bottom: ${props => props.stretch ? "1px" : "0px"} solid black;
    width: ${props => props.width}%;
    font-size: 10pt;
    height: 12.5pt;
    flex: ${props => props.stretch && 1};
    margin-right: ${props => (!props.stretch || props.notLast) && '2mm'};
    margin-bottom: ${({caption, captionLines}) =>
            caption && 1 + (captionLines ? 3 * captionLines : 3) + 'mm'};
    text-align: center;

    &:before {
        box-sizing: border-box;
        display: block;
        content: "${props => props.caption}";
        position: absolute;
        top: calc(100% + 1mm);
        width: max-content;
        font-size: 7pt;
    }

`
