import {createSelector} from 'reselect'
import paymentTypes from '../../../../constants/paymentTypes'

export const checksSelector = state => state.checks

export const items = state => checksSelector(state).items

export const selectedKKT = state => checksSelector(state).selectedKKT

export const showCashless = state => checksSelector(state).showCashless

export const filteredChecksByKKT = createSelector(
    items, selectedKKT,
    (items, selectedKkt) => selectedKkt ? items.filter(({kkt}) => kkt === selectedKkt) : items)

export const kktList = createSelector(items, items =>
    [...new Set(items?.map(({kkt}) => kkt).filter(it => it))]
        .reduce((a, b) => {
            a.push({label: b, value: b})
            return a
        }, [{label: "Все", value: ""}]))

export const isFailed = state => checksSelector(state).isFailed
export const isLoading = state => checksSelector(state).isLoading


const getTotal = (items, paymentType) =>
    items
        .filter(item => item.payment_type === paymentTypes[paymentType])
        .reduce((total, item) => total + item.total, 0)

const getAllTotals = (items, neededPaymentTypes) => {
    const result = {}
    let totalForAll = 0

    neededPaymentTypes.forEach(PT => {
        const tmpTotal = getTotal(items, PT)
        result[PT] = tmpTotal
        totalForAll += tmpTotal
    })

    result.all = totalForAll

    return result
}

export const checksStatsSelector = createSelector(filteredChecksByKKT, items => {
    const sells = items.filter(item => !item.isRefund)
    const refunds = items.filter(item => item.isRefund)

    return {
        sells: {
            count: sells.length,
            totals: getAllTotals(sells, ['cash', 'card', 'bank']),
        },
        refunds: {
            count: refunds.length,
            totals: getAllTotals(refunds, ['cash', 'card', 'bank']),
        },
    }
})
