import Cookies from 'js-cookie'
import * as keys from './keys'
import moment from "moment/moment";

// SESSION
export const getSavedSession = () => Cookies.getJSON(keys.SESSION) || {}
export const saveSession = session => Cookies.set(keys.SESSION, session)

export const clearSession = () => Cookies.remove(keys.SESSION)

// API
export const getSavedAPIToken = () => Cookies.get(keys.API_TOKEN) || null
export const saveAPIToken = token => Cookies.set(keys.API_TOKEN, token)

//SHIFTS
export const getExpiredTime = () => {
    const {username} = getSavedSession()
    const data = Cookies.getJSON(keys.TIME_TO_COMMIT_CHECK_LISTS) || {}
    return data && username ? data[username] : null
}
export const saveExpiredTime = minutes => {
    const {username} = getSavedSession()
    const data = Cookies.getJSON(keys.TIME_TO_COMMIT_CHECK_LISTS) || {}
    const time = moment().add(minutes, 'minutes').format('YYYY-MM-DD HH:mm')
    Cookies.set(keys.TIME_TO_COMMIT_CHECK_LISTS, {...data, [username]: time})
}
export const clearExpiredTime = () => {
    const {username} = getSavedSession()
    const data = Cookies.getJSON(keys.TIME_TO_COMMIT_CHECK_LISTS) || {}
    delete data[username]
    if (Object.keys(data).length)
        Cookies.set(keys.TIME_TO_COMMIT_CHECK_LISTS, data)
    else Cookies.remove(keys.TIME_TO_COMMIT_CHECK_LISTS)
}
export const isTimeExpired = () => {
    const {username} = getSavedSession()
    const data = Cookies.getJSON(keys.TIME_TO_COMMIT_CHECK_LISTS) || {}
    const time = data && username ? data[username] : null
    if (!time) return false
    return time ? moment(time, 'YYYY-MM-DD HH:mm').isBefore(moment()) : false
}
