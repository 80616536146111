import React, {useEffect} from 'react'
import {DefaultTemplate} from '../../ui/templates'
import {ThemeProvider} from '../../ui/theme'
import Styles from '../../styles'
import {SelectionForm} from './components/SelectionForm'
import {ChecksTable} from './components/ChecksTable'
import {RefundsTable} from './components/RefundsTable'
import Note from './components/Note'
import Results from './components/Results'
import LeftSide from './components/LeftSide'
import RightSide from './components/RightSide'
import {ChecksStats} from './components/ChecksStats'
import Status from './components/Status'
import moment from 'moment'
import PrintButton from './components/PrintButton'
import {ControlLabel, FormControl} from "react-bootstrap";
import ChecksFormBlock from "./components/SelectionForm/components/ChecksFormBlock";
import {useSelector} from "react-redux";
import {useActions} from "../../../common/hooks/use-actions";
import {checksActions, checksSelectors} from "./reducers/checks";
import {refundsActions, refundsSelectors} from "./reducers/refunds";
import {sessionSelectors} from "../../../../Modules/session";

export const DayResult = () => {

    const lcode = useSelector(sessionSelectors.lcode)

    const {getChecks} = useActions(checksActions)
    const {getRefunds} = useActions(refundsActions)
    const {setSelectedKKT, setShowCashless} = useActions(checksActions)

    const manageFilters = val => {
        setShowCashless(val)
        if(val) {
            setSelectedKKT("")
        }
    }

    useEffect(() => {
        const date = moment().format('YYYY-MM-DD')
        getChecks({lcode, date})
        getRefunds({lcode, date})
    }, [lcode])

    return (
        <React.Fragment>
            <Styles/>
            <ThemeProvider>
                <DefaultTemplate>
                    <SelectionForm
                        lcode={lcode}
                        getChecks={getChecks}
                        getRefunds={getRefunds}
                        manageFilters={manageFilters}
                    />
                    <CheckTable/>
                </DefaultTemplate>
            </ThemeProvider>
        </React.Fragment>
    )
}

const CheckTable = ({}) => {

    const {setSelectedKKT} = useActions(checksActions)
    const checksStats = useSelector(checksSelectors.checksStatsSelector)

    const isLoadingChecks = useSelector(checksSelectors.isLoading)
    const isFailedChecks = useSelector(checksSelectors.isFailed)
    const checks = useSelector(checksSelectors.filteredChecksByKKT)
    const selectedKKT = useSelector(checksSelectors.selectedKKT)
    const kktList = useSelector(checksSelectors.kktList)
    const showCashless = useSelector(checksSelectors.showCashless)

    const isLoadingRefunds = useSelector(refundsSelectors.isLoading)
    const isFailedRefunds = useSelector(refundsSelectors.isFailed)
    const refunds = useSelector(refundsSelectors.filteredRefundsByKKT)

    const kkt = localStorage.getItem('kkt')

    if (isLoadingChecks || isLoadingRefunds) {
        return <Status>Загрузка...</Status>
    }
    if (!checks.length) {
        return <Status>Используйте форму выше для получения данных</Status>
    }

    return (
        <React.Fragment>
            <ChecksFormBlock>
                <ControlLabel>Выберите кассу</ControlLabel>
                <FormControl
                    componentClass="select"
                    disabled={showCashless}
                    value={selectedKKT}
                    style={{marginBottom: 10}}
                    onChange={e => setSelectedKKT(e.target.value)}
                >
                    {kktList.map(({label, value}) => (
                        <option key={value} value={value}>
                            {label}
                        </option>
                    ))}
                </FormControl>
                {kkt && <p>Номер кассы: <strong>{kkt}</strong></p>}
            </ChecksFormBlock>

            <PrintButton
                data={{checks, checksStats, refunds, showCashless}}
                disabled={isFailedRefunds || isFailedChecks}
            />
            <Note color="pageText">
                Чтобы показать подробную информацию по чеку, нажмите на нужную строку
                таблицы
            </Note>
            <Results>
                <LeftSide>
                    <ChecksTable isFailed={isFailedChecks} items={checks}/>
                    <RefundsTable items={refunds} isFailed={isFailedRefunds}/>
                </LeftSide>
                <RightSide>
                    <ChecksStats checks={checks} isFailed={isFailedChecks} checksStats={checksStats} showCashless={showCashless}/>
                </RightSide>
            </Results>
        </React.Fragment>
    )
}
