import React from 'react'
import classNames from "classnames";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import moment from "moment";

export const DateCell = ({date, params}) => {
    const dateObj = new Date(date)

    const weekdayOptions = {
        weekday: 'short',
    }

    const dayOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    }
    const emptyCellClass = classNames({
        'date-cell': true,
        'red': params?.red,
        'orange': params?.orange,
        'yellow': params?.yellow,
    })

    return (
        <OverlayTrigger
            placement="right"
            delayHide={0}
            delayShow={200}
            overlay={
                <Tooltip id='cell-date-tooltip'>
                    <div>На <strong>{moment(date).format('DD.MM.YYYY')}</strong> с учетом ожидаемых заездов:</div>
                    {params?.categories?.map(({name, num}) => <div key={name}><strong>{name}</strong>: {num}</div>)}
                </Tooltip>
            }
        >
            <div className={emptyCellClass}>
                <div className="date-cell-weekday">
                    {dateObj.toLocaleString('ru', weekdayOptions)}
                </div>
                <div className="date-cell-day">
                    <div className="date-inner">
                        {dateObj.toLocaleString('ru', dayOptions)}
                    </div>
                </div>
            </div>
        </OverlayTrigger>
    )
}
