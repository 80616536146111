import React, {memo, useEffect, useRef} from 'react'
import styled from 'styled-components'
import {ControlsCell} from './components/ControlsCell'
import {useDispatch, useSelector} from 'react-redux'
import {settingsSelectors} from '../../../../Modules/settings'
import {DateCell} from './components/DateCell'
import {gridActions, gridSelectors} from '../../reducers/grid'
import {RoomsColumn} from './components/RoomColumn'
import AutoSizer from 'react-virtualized-auto-sizer'
import {areEqual, FixedSizeGrid} from 'react-window'
import './styles.css'

import {onNewReservationClick, onReservationClick,} from '../../../../Modules/helpers/gridFunctions'
import EmptyCell from './components/EmptyCell'
import BodyCell from './components/BodyCell'
import {GridContainer} from './components/GridContainer'
import {LoadingScreen} from './components/LoadingScreen'
import {ErrorScreen} from './components/ErrorScreen'
import {useActions} from '../../../common/hooks/use-actions'
import {GridTooltip} from './components/GridTooltip'
import RoomNameTooltip from './components/RoomNameTooltip'
import throttle from 'lodash.throttle'
import * as TooltipHelpers from './tooltip/helpers'
import {tooltipRef} from './tooltip/use-tooltip-ref'
import * as SelectionHelpers from './selection/helpers'
import ReactTooltip from 'react-tooltip'
import {roomsSelectors} from '../../../../Modules/rooms'
import {SelectionState} from './selection/selection-state'
import {ccAPI} from '../../../../Modules/api/ccAPI'
import {callModal, modalTemplates} from '../../../dynamic-modal'
import moment from 'moment'
import {sessionSelectors} from "../../../../Modules/session";
import {shiftsSelectors} from "../../../../Modules/shifts";
import {openShiftModalTimeExpired} from "../../../Shifts/get-shift-modal";
import {isTimeExpired} from "../../../DayTotals/helpers/CookiesAPI/changers";

const throttledUpdateTooltipState = throttle(
    TooltipHelpers.updateTooltipState,
    100
)

const handlers = {
    filled: onReservationClick,
    empty: onNewReservationClick,
}

const GridLayout = styled.div`
    flex-grow: 1;
    height: 100%;
    display: grid;
    grid-template-areas:
    'controls dates-row'
    'rooms-column reservation-cells';
    grid-template-columns: 200px auto;
    grid-template-rows: 160px auto;
    user-select: none;
`

const ControlsContainer = styled.div`
    grid-area: controls;
`

const DatesRowContainer = styled.div`
    grid-area: dates-row;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
`

const EmptyDateCell = styled.div`
    flex-grow: 1;
    min-width: 50px;
    background-color: slateblue;
    border-left: 1px solid #483d8b;
`

const RoomsColumnContainer = styled.div`
    grid-area: rooms-column;
    overflow: hidden;
`

const ReservationCellsContainer = styled.div`
    grid-area: reservation-cells;
`

const DatesRow = memo(() => {
    const dates = useSelector(gridSelectors.dates)
    const dateStatus = useSelector(gridSelectors.dateStatus)

    return (
        <>
            {dates.map(date => (
                <DateCell key={date} date={date} params={dateStatus[date]}/>
            ))}
            <EmptyDateCell/>
        </>
    )
})

const PureCell = ({data, rowIndex, columnIndex, style}) => {
    const {
        model,
        reservations,
        mapRowIndexToRoom,
        mapColumnIndexToDate,
        usedDate,
        isRoomStatusesEnabled,
        roomOccupancy
    } = data

    const {room_id, repair_schedule, status, busy_with_employee_dates} = mapRowIndexToRoom[rowIndex]
    const date = mapColumnIndexToDate[columnIndex]

    const isRepair = isRoomStatusesEnabled && repair_schedule.find(({start_date, end_date}) => {
        const start = moment(start_date)
        const end = moment(end_date)
        const day = moment(date)
        return day.isBetween(start, end) || day.isSame(start) || day.isSame(end)
    })

    const isBusyByEmployee = isRoomStatusesEnabled && busy_with_employee_dates.find(({start_date, end_date}) => {
        const start = moment(start_date)
        const end = moment(end_date)
        const day = moment(date)
        return day.isBetween(start, end) || day.isSame(start) || day.isSame(end)
    })

    const cellData = model?.[room_id]?.[date]

    if (!cellData)
        return (
            <EmptyCell
                style={style}
                roomID={room_id}
                date={date}
                today={usedDate}
                isRepair={isRepair}
                isBusyByEmployee={isBusyByEmployee}
                status={status}
                count={roomOccupancy?.[date]?.[room_id] || 1000}
            />
        )

    const reservationData = reservations[cellData.reservation]

    return (
        <BodyCell
            style={style}
            roomID={room_id}
            date={date}
            reservationData={reservationData}
            cellData={cellData}
            clickHandler={() => handlers.filled(cellData.reservation)}
        />
    )
}

const Cell = memo(PureCell, areEqual)

const mapSizeToCellSize = {
    100: 50,
    80: 44,
    60: 38,
    40: 27,
}

const LoadedContent = () => {
    const itemData = useSelector(gridSelectors.gridItemData)
    const mapRoomIdToRoom = useSelector(roomsSelectors.mapRoomIdToRoom)
    const dates = useSelector(gridSelectors.dates)
    const rooms = useSelector(roomsSelectors.items)
    const size = useSelector(settingsSelectors.gridScale)
    const isRoomStatusesEnabled = useSelector(settingsSelectors.isRoomStatusesEnabled)
    const isChecklistsCompleted = useSelector(shiftsSelectors.isChecklistsCompleted)

    const cellsContainerRef = useRef()
    const innerContainerRef = useRef()
    const outerContainerRef = useRef()
    const roomsColumnRef = useRef()
    const datesRowRef = useRef()

    const shiftAccepted = useSelector((shiftsSelectors.isShiftOpened))
    const lcode = useSelector(sessionSelectors.lcode)

    useEffect(() => {
        ReactTooltip.rebuild()
    })

    useEffect(() => {
        return () => TooltipHelpers.resetTooltip()
    }, [])

    const cellSize = mapSizeToCellSize[size]

    const handleMouseMove = event => {
        event.persist()
        TooltipHelpers.updateTooltipPosition({event, tooltipRef})
        throttledUpdateTooltipState({eventTarget: event.target})
        SelectionHelpers.updateSelectionState({event})
    }

    const handleMouseDown = event => {
        SelectionHelpers.handleMouseDown({event})
    }

    const checkAvailability = async (date, room) => {
        return await ccAPI.getLateCheckouts(date).then(async res => {
            const busy = res.find(it => it.room === room)
            if (busy) {
                const confirm = await callModal(
                    modalTemplates.confirm({
                        text:
                            `В заселяемом номере имеется активный поздний выезд с временем выезда ${busy['check-out']}. Вы уверены, что хотите заселить в этот номер?`,
                        buttons: ['Нет', 'Да'],
                    })
                )
                return confirm.action === modalTemplates.confirm.actions.confirm
            }
            return true
        })
    }
    const handleMouseUp = async (event) => {
        const {start, end, selectedRoom} = SelectionState.getState()
        if (selectedRoom) {

            const fillCheckListExpiredDate = isTimeExpired()

            if (!isChecklistsCompleted && fillCheckListExpiredDate) {
                await openShiftModalTimeExpired(lcode, shiftAccepted)
                return
            }

            checkAvailability(start, selectedRoom).then(res => {
                if (res) {
                    SelectionState.updateState({
                        isActive: true,
                        selectedRoom,
                        start,
                        end,
                    })
                    SelectionHelpers.handleMouseUp({
                        mapRoomIdToRoom,
                        start,
                        end,
                        selectedRoom,
                        isRoomStatusesEnabled
                    })
                }
            })
        } else {
            await SelectionHelpers.handleMouseUp({mapRoomIdToRoom, isRoomStatusesEnabled})
        }
    }

    const handleWheel = event => {
        TooltipHelpers.handleWheel({event, cellsContainerRef, outerContainerRef})
    }

    const handleMouseLeave = () => {
        throttledUpdateTooltipState.cancel()
        TooltipHelpers.resetTooltip()
        SelectionHelpers.resetSelection()
    }

    return (
        <GridLayout>
            <ControlsContainer>
                <ControlsCell/>
            </ControlsContainer>
            <DatesRowContainer ref={datesRowRef}>
                <DatesRow/>
            </DatesRowContainer>
            <RoomsColumnContainer ref={roomsColumnRef}>
                <RoomsColumn/>
            </RoomsColumnContainer>
            <ReservationCellsContainer
                ref={cellsContainerRef}
                onMouseMove={handleMouseMove}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onWheel={handleWheel}
                onMouseLeave={handleMouseLeave}
            >
                <AutoSizer>
                    {({width, height}) => {
                        return (
                            <FixedSizeGrid
                                width={width - 1}
                                height={height - 1}
                                columnCount={dates.length}
                                columnWidth={cellSize}
                                rowCount={rooms.length}
                                rowHeight={cellSize}
                                itemData={itemData}
                                innerRef={innerContainerRef}
                                outerRef={outerContainerRef}
                                onScroll={({scrollLeft, scrollTop}) => {
                                    if (roomsColumnRef.current) {
                                        roomsColumnRef.current.scrollTop = scrollTop
                                    }

                                    if (datesRowRef.current) {
                                        datesRowRef.current.scrollLeft = scrollLeft
                                    }
                                }}
                            >
                                {Cell}
                            </FixedSizeGrid>
                        )
                    }}
                </AutoSizer>
            </ReservationCellsContainer>
        </GridLayout>
    )
}

const Content = () => {
    const lcode = useSelector(sessionSelectors.lcode)
    const isRoomsLoading = useSelector(roomsSelectors.isLoading)
    const isGridLoading = useSelector(gridSelectors.isLoading)
    const isGridLoaded = useSelector(gridSelectors.isLoaded)
    const isGridFailed = useSelector(gridSelectors.isFailed)
    const gridRange = useSelector(gridSelectors.gridRange)
    const gridError = useSelector(gridSelectors.error)
    const isSettingsLoading = useSelector(settingsSelectors.isLoading)
    const {loadData, getRoomOccupancy} = useActions(gridActions)

    useEffect(() => {
        getRoomOccupancy({...gridRange, lcode})
    }, [gridRange, lcode]);

    if (isRoomsLoading || isGridLoading || isSettingsLoading)
        return <LoadingScreen/>

    if (isGridFailed)
        return <ErrorScreen text={gridError} reloadAction={loadData}/>

    if (!isGridLoaded) return null

    return <LoadedContent/>
}

export const Grid = () => {
    const size = useSelector(settingsSelectors.gridScale)
    const containerClassName = `grid-size-${size}`

    return (
        <GridContainer className={containerClassName}>
            <Content/>
            <GridTooltip/>
            <RoomNameTooltip/>
        </GridContainer>
    )
}
