import * as types from './types'
import API from '../../../../helpers/API'
import {ccAPI} from '../../../../../../Modules/api/ccAPI'

const getChecksRequest = () => ({
    type: types.GET_CHECKS_REQUEST,
})

const getChecksSuccess = items => ({
    type: types.GET_CHECKS_SUCCESS,
    payload: {
        items,
    },
})

const getChecksCCItems = itemsCC => ({
    type: types.GET_CHECKS_BY_UUID,
    payload: {itemsCC},
})

const getChecksFailure = error => ({
    type: types.GET_CHECKS_FAILURE,
    payload: {
        error,
    },
})

/**
 * Get checks from server and assign result to checks reducer
 * @param {object} data
 * @param {string} data.lcode - Hotel lcode
 * @param {string} data.date - Selected date in YYYY-MM-DD format
 * @returns {boolean} Is the data received successfully
 */
export const getChecks = ({lcode, date, cashless_include}) => async dispatch => {
    dispatch(getChecksRequest())

    try {
        dispatch(setShowCashless(cashless_include))
        const items = await API.getChecks({lcode, dateStart: date, dateEnd: date, cashless_include})
        dispatch(getChecksSuccess(items))
    } catch (err) {
        dispatch(getChecksFailure())
    }
}

export const getCheckByUUID = uuid => async dispatch => {
    try {
        const itemsCC = await ccAPI.getChecksByUUID(uuid).then(res => res)
        dispatch(getChecksCCItems(itemsCC.details))
    } catch (err) {
        dispatch(getChecksFailure())
    }
}

export const reset = () => ({
    type: types.RESET,
})

export const setSelectedKKT = selectedKKT => {
    return ({
        type: types.SET_SELECTED_KKT,
        payload: {
            selectedKKT,
        },
    })
}

export const setShowCashless = showCashless => {
    return ({
        type: types.SET_SHOW_CASHLESS,
        payload: {
            showCashless,
        },
    })
}
