import React from 'react'
import TableStatsBlock from './components/TableStatsBlock'
import { BlockWithIcon } from '../../shared/HelpStyles/BlockWithIcon'
import { Text } from '../../shared/HelpStyles/Text'
import { RefundIcon, SellIcon } from '../../../../ui/icons/shopping'
import StatsPart from './components/StatsPart'
import { StatsContainer } from './components/StatsContainer'
import TableTitle from '../../shared/TableTitle'

export class ChecksStats extends React.Component {
  renderStats = () => {
    const { checks, isFailed, checksStats, showCashless } = this.props

    if (isFailed) {
      return <div>Ошибка загрузки</div>
    }

    if (!checks.length) {
      return <div>Нет данных для отображения</div>
    }

    const { sells, refunds } = checksStats

    return (
      <React.Fragment>
        <StatsPart
          name={
            <BlockWithIcon color="checksTableSuccess">
              <SellIcon />
              <Text>Продажи - {sells.count} шт.</Text>
            </BlockWithIcon>
          }
          totals={sells.totals}
          showCashless={showCashless}
        />
        <StatsPart
          name={
            <BlockWithIcon color="checksTableFailure">
              <RefundIcon />
              <Text>Возвраты - {refunds.count} шт.</Text>
            </BlockWithIcon>
          }
          totals={refunds.totals}
          showCashless={showCashless}
        />
      </React.Fragment>
    )
  }

  render() {
    return (
      <TableStatsBlock>
        <TableTitle>Итоги дня</TableTitle>
        <StatsContainer>{this.renderStats()}</StatsContainer>
      </TableStatsBlock>
    )
  }
}
