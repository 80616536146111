import {createSelector} from 'reselect'

export const everything = state => state.session

export const adminName = state => everything(state).admin
export const adminId = state => everything(state).adminID
export const isAuthorized = state => everything(state).isAuthorized
export const lcode = state => everything(state).lcode
export const lcodeName = state => everything(state).lcodeName
export const gridScale = state => everything(state).gridScale

export const lcodeObj = createSelector(everything, everything => everything.lcodeObj)
