import React from 'react'
import {Button, Col, Grid, Row} from 'react-bootstrap'
import moment from 'moment'
import 'moment/locale/ru'
import {day} from '../../../Modules/formatters/date'
import {FaRegCheckSquare} from "react-icons/fa";
import styled from 'styled-components'

const Flex = styled.p`
    display: flex;
    align-items: center;
    gap: 2px;
    margin-bottom: 10px;
`

moment.locale('ru')

const StyledCol = props => {
    return (
        <Col {...props} style={{fontSize: 18, marginBottom: 15}}>
            {props.children}
        </Col>
    )
}

const PrePayedBreakfast = ({breakfast, click, editable}) => {
    if (!breakfast) return null

    return (
        <Grid fluid={true}>
            <Row>
                <StyledCol xs={2}>{day(breakfast.delivery_date)}</StyledCol>
                <StyledCol xs={3}>{breakfast.breakfastObj ? breakfast.breakfastObj.name : ''}</StyledCol>
                <StyledCol xs={1}>{breakfast.qty}</StyledCol>
                <StyledCol xs={4}>
                    {breakfast.is_complementary && <Flex><FaRegCheckSquare/>Комплиментарный</Flex>}
                    {breakfast.note}
                </StyledCol>
                <StyledCol xs={2}>
                    {editable && <Button
                        onClick={click}
                        style={{marginBottom: 0}}
                        size="small"
                        className={'btn-success'}
                    >
                        <span className="glyphicon glyphicon-pencil"/>
                    </Button>}
                </StyledCol>
            </Row>
        </Grid>
    )
}

export default PrePayedBreakfast
