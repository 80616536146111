import { dayTypes, serviceTypes } from '../../../../helpers/types'

export const getItems = reservation => {
  const { reserved_days: days, additional_services: services } = reservation

  const purchasedDays = []
  const purchasedServices = []

  const validDayTypes = [dayTypes.new, dayTypes.notPayed]
  for (let day of days) {
    if(day.prepaid) {
      purchasedDays.push(day)
    }
    if (!validDayTypes.includes(day.type)) continue
    if (!day.payment_date || !day.payment_type) continue

    purchasedDays.push(day)
  }

  for (let service of services) {
    if (service.type !== serviceTypes.new) continue

    purchasedServices.push(service)
  }

  return {
    days: purchasedDays,
    services: purchasedServices,
  }
}
