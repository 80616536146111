import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {shiftsSelectors} from "../../Modules/shifts";
import styled from 'styled-components'
import {Link} from "react-router-dom";
import {settingsSelectors} from "../../Modules/settings";
import Crontab from "reactjs-crontab";
import {sessionSelectors} from "../../Modules/session";
import moment from "moment/moment";
import {openShiftModalTimeExpired} from "./get-shift-modal";
import {getExpiredTime, isTimeExpired} from "../DayTotals/helpers/CookiesAPI/changers";

const Alert = styled.div`
    background-color: #ed143d;
    border-radius: 16px;
    padding: 8px;
    margin: 10px 30px 0;
    text-align: center;

    a {
        color: white;
    }
`

export const FillCheckListAlert = () => {
    const isChecklistsCompleted = useSelector(shiftsSelectors.isChecklistsCompleted)
    const isShiftOpened = useSelector(shiftsSelectors.isShiftOpened)

    const lcode = useSelector(sessionSelectors.lcode)

    const isLoadedSettings = useSelector(settingsSelectors.isLoaded)
    const isLoadedShifts = useSelector(shiftsSelectors.isLoaded)

    const expiredFillCheckListTime = getExpiredTime()

    const [isNotExpiredFillTime, setNotExpiredFillTime] = useState(!isTimeExpired())

    const expiredFillCheckListTimePretty = expiredFillCheckListTime ? moment(expiredFillCheckListTime, "'YYYY-MM-DD HH:mm'").format("HH:mm") : null

    let cron = null
    if (isNotExpiredFillTime) {
        const expDate = moment(expiredFillCheckListTime || 1432252800)
        const fillCheckListTimeHasExpired = () => {
            setNotExpiredFillTime(false)
            openShiftModalTimeExpired(lcode, isShiftOpened)
        }
        cron = <Crontab
            tasks={[{
                fn: fillCheckListTimeHasExpired,
                config: expDate.minutes() + " " + expDate.hour() + ' * * *'
            }]}
            timeZone="Europe/Moscow"
            // timeZone='Asia/Makassar'
            dashboard={{
                hidden: true
            }}
        />
    }
    if (!isLoadedShifts || !isLoadedSettings) return <></>
    if (!isShiftOpened) return <Alert>
        {cron}
        <Link to="/check-list">
            Не забудьте открыть смену и заполнить чек-листы.
            <br/>{isNotExpiredFillTime
            ? `Функционал работы с бронями будет ограничен в ${expiredFillCheckListTimePretty}`
            : 'Взаимодействие с бронированиями ограничено'}
        </Link>
    </Alert>
    if (!isChecklistsCompleted)
        return <Alert>
            {cron}
            <Link to="/check-list">
                Заполните чек-листы.
                {isNotExpiredFillTime
                    ? ` Функционал работы с бронированиями будет ограничен в ${expiredFillCheckListTimePretty}`
                    : ' Взаимодействие с бронированиями ограничено'}
            </Link>
        </Alert>
    return <div/>
}
