import React from 'react'
import {FaShoppingCart as SellIcon, FaUndoAlt as RefundIcon,} from 'react-icons/fa'

const CashIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="14"
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
    >
        <path
            d="M3.5 10H3v-.5c0-.658-1-.67-1 0v1c0 .262.238.5.5.5h1c.658 0 .67-1 0-1zm8 0h.5v-.5c0-.658 1-.67 1 0v1c0 .262-.238.5-.5.5h-1c-.658 0-.67-1 0-1zm-8-5H3v.5c0 .658-1 .67-1 0v-1c0-.262.238-.5.5-.5h1c.658 0 .67 1 0 1zM7 4.5v.594c-.585.204-1 .753-1 1.406 0 1.03.67 1.484 1.5 1.5.275.01.5.236.5.5 0 .274-.23.5-.5.5h-1c-.662 0-.66 1 0 1H7v.5c0 .667 1 .665 1 0v-.563c.586-.204 1-.783 1-1.437 0-.826-.597-1.492-1.5-1.492-.282 0-.5-.236-.5-.508 0-.274.23-.5.5-.5h1c.704 0 .613-1 0-1H8v-.5c0-.666-1-.656-1 0zm4.5.5h.5v.5c0 .658 1 .67 1 0v-1c0-.262-.238-.5-.5-.5h-1c-.658 0-.67 1 0 1zm-10-3C.678 2 0 2.678 0 3.5v8c0 .822.678 1.5 1.5 1.5h12c.822 0 1.5-.678 1.5-1.5v-8c0-.822-.678-1.5-1.5-1.5zm0 1h12c.286 0 .5.214.5.5v8c0 .286-.214.5-.5.5h-12c-.286 0-.5-.214-.5-.5v-8c0-.286.214-.5.5-.5z"/>
    </svg>
)

const CardIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="14"
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
    >
        <path
            d="M11.5 10h1c.68 0 .652 1 0 1h-1c-.668 0-.65-1 0-1zm-3 0h1c.68 0 .652 1 0 1h-1c-.668 0-.654-1 0-1zm-3 0h1c.68 0 .652 1 0 1h-1c-.668 0-.654-1 0-1zm-3 0h1c.68 0 .652 1 0 1h-1c-.668 0-.67-1 0-1zM10 4c-1.1 0-2 .9-2 2s.9 2 2 2c.392 0 .702-.216 1-.406.298.19.608.406 1 .406 1.1 0 2-.9 2-2s-.9-2-2-2c-.375 0-.695.192-1 .375-.305-.183-.625-.375-1-.375zm0 1c.26 0 .483.096.656.25.184.174.504.174.688 0 .173-.154.395-.25.656-.25.558 0 1 .442 1 1s-.442 1-1 1c-.246 0-.466-.11-.656-.28-.184-.175-.504-.175-.688 0-.19.17-.41.28-.656.28-.558 0-1-.442-1-1s.442-1 1-1zM1.5 2C.678 2 0 2.678 0 3.5v8c0 .822.678 1.5 1.5 1.5h13c.822 0 1.5-.678 1.5-1.5v-8c0-.822-.678-1.5-1.5-1.5zm0 1h13c.286 0 .5.214.5.5v8c0 .286-.214.5-.5.5h-13c-.286 0-.5-.214-.5-.5v-8c0-.286.214-.5.5-.5z"/>
    </svg>
)

const BankIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="16"
         height="16"
         viewBox="0 0 512 512">
        <path
            d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160l0 8c0 13.3 10.7 24 24 24l400 0c13.3 0 24-10.7 24-24l0-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8-3.4-17.2-3.4-25.2 0zM128 224l-64 0 0 196.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512l448 0c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1L448 224l-64 0 0 192-40 0 0-192-64 0 0 192-48 0 0-192-64 0 0 192-40 0 0-192zM256 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
    </svg>
)

export {SellIcon, RefundIcon, CashIcon, CardIcon, BankIcon}
