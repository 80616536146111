import React, {Component} from 'react'
import {Formik} from 'formik'
import * as Yup from 'yup'
import ChecksFormBlock from './components/ChecksFormBlock'
import Form from '../../../../ui/Form'
import FormFields from './components/FormFields'
import ChecksFormField from './components/ChecksFormField'
import SubmitButton from './components/SubmitButton'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils, {formatDate, parseDate,} from 'react-day-picker/moment'
import moment from 'moment'
import 'moment/locale/ru'
import FormError from '../../../../ui/FormError'
import {Checkbox} from "react-bootstrap";

const DateSchema = Yup.string().required()

export class SelectionForm extends Component {
    state = {
        errors: [],
    }

    addError = error =>
        this.setState(prev => ({...prev, errors: prev.errors.concat(error)}))
    clearErrors = () => this.setState({errors: []})

    render() {
        const {lcode, getChecks, getRefunds, manageFilters} = this.props

        const initialDate = moment().format('DD.MM.YYYY')

        return (
            <ChecksFormBlock>
                <Formik
                    initialValues={{date: initialDate, showCashLess: false}}
                    onSubmit={async (values, actions) => {
                        // Reset errors
                        this.clearErrors()

                        let isValid = true

                        const error = error => {
                            this.addError(error)
                            isValid = false
                        }

                        const {date, showCashLess} = values

                        const dateMomentObj = moment(date, 'DD.MM.YYYY')

                        // Validate date
                        if (!DateSchema.isValidSync(date) || !dateMomentObj.isValid())
                            error('Дата должна быть заполнена и валидна')

                        if (isValid) {
                            const formattedDate = dateMomentObj.format('YYYY-MM-DD')

                            const promises = []

                            promises.push(
                                getChecks({
                                    lcode,
                                    date: formattedDate,
                                    cashless_include: showCashLess
                                })
                            )

                            promises.push(
                                getRefunds({
                                    lcode: lcode,
                                    date: formattedDate,
                                })
                            )

                            await Promise.all(promises)
                        }

                        actions.setSubmitting(false)
                    }}
                    render={props => (
                        <Form onSubmit={props.handleSubmit}>
                            <FormFields>
                                <DayPickerInput
                                    placeholder="Дата"
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    value={initialDate}
                                    onDayChange={date =>
                                        props.setFieldValue(
                                            'date',
                                            moment(date).format('DD.MM.YYYY')
                                        )
                                    }
                                    component={ChecksFormField}
                                    dayPickerProps={{
                                        locale: 'ru',
                                        localeUtils: MomentLocaleUtils,
                                    }}
                                />
                            </FormFields>
                            <Checkbox
                                type="checkbox"
                                value={props.values.showCashLess}
                                name="showCashLess"
                                style={{marginBottom: 10}}
                                onChange={_ => {
                                    props.setFieldValue(
                                        'showCashLess',
                                        !props.values.showCashLess
                                    )
                                    manageFilters(!props.values.showCashLess)
                                }}
                            >
                                Отображать безнал
                            </Checkbox>
                            <SubmitButton disabled={props.isSubmitting}>
                                Показать данные
                            </SubmitButton>

                            {this.state.errors.map((error, idx) => (
                                <FormError key={idx}>{error}</FormError>
                            ))}
                        </Form>
                    )}
                />
            </ChecksFormBlock>
        )
    }
}
