import Cookies from 'js-cookie';
import {getAdmin, restorePassword, signIn} from '../api/ccAPI/requests';
import {Scheduler} from '../scheduler';
import {CriticalOperations} from '../critical-operations';
import * as types from './types';
import {clearSession, saveSession} from "../../Components/DayTotals/helpers/CookiesAPI/changers";

const loginAdminSuccess = adminData => dispatch => {
    dispatch({
        type: types.LOGIN_SUCCESS,
        payload: adminData,
    })
    saveSession(adminData)
}

export const loginAdmin = (username, password) => async dispatch => {
    dispatch({type: types.ERROR_CLEAR})
    dispatch({type: types.LOADING_STARTED})

    try {
        const data = {
            username,
            password,
        }
        const accessData = await signIn(data)
        if (accessData) {
            localStorage.setItem(types.CC_TOKEN, accessData.access)
            const {name: admin, uid: adminID, username} = await getAdmin()
            dispatch(loginAdminSuccess({
                admin,
                adminID, username
            }))
        } else {
            throw new Error('401')
        }
    } catch (error) {
        const message =
            error.message === '401' ? 'Неверные логин пароль' : 'Неизвестная ошибка'

        dispatch({type: types.LOGIN_FAILED})
        dispatch({
            type: types.ERROR_SET,
            payload: {error: message},
        })
    }

    dispatch({type: types.LOADING_ENDED})
}

export const logoutAdmin = () => dispatch => {
    dispatch({type: types.LOGOUT})
    clearSession()
    Cookies.remove(types.LCODE)
    localStorage.removeItem(types.CC_TOKEN)
}

export const setSessionError = error => ({
    type: types.ERROR_SET,
    payload: {error},
})

export const clearSessionError = () => ({
    type: types.ERROR_CLEAR,
})

export const setupAutoLogOut = () => dispatch => {
    Scheduler.addTask({
        time: {
            hours: 10,
            minutes: 15,
        },
        action: async () => {
            await CriticalOperations.waitForFinish()
            dispatch(logoutAdmin())
        },
        isEveryDay: true,
    })
}

export const setCookieLcode = (lcode) => dispatch => {
    Cookies.set(types.LCODE, lcode)
    dispatch({
        type: types.LCODE,
        payload: lcode,
    })
}

export const resetPassword = (email) => async () => await restorePassword(email)

export const maidLogin = ({admin, adminID, token}) => dispatch => {
    dispatch(loginAdminSuccess({
        admin,
        adminID
    }))
    localStorage.setItem(types.CC_TOKEN, token)

}

