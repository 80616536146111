import React from 'react'

import {Box, Date, Field, Name, Page, Text, Title} from './FieldUtils'
import SummaryTable from './SummaryTable'
import {getValuesFromData} from '../helpers/getValuesFromData'
import moment from "moment";

export const ActContent = ({
                               hotelName,
                               personData,
                               reservation,
                               calculatedItems,
                               breakfasts
                           }) => {
    const values = getValuesFromData({reservation, calculatedItems, breakfasts})

    return (
        <Page>
            <Date>
                от {moment(values.end).format('DD MMMM YYYY')} г.
            </Date>
            <Title> Акт №1<br/>
                о приемке выполненных работ<br/>
                (оказанных услуг)</Title>
            <br/>
            <Field>
                <br/>
                <Name>Исполнитель:</Name>
                <strong>{personData.name}</strong>, ИНН {personData.inn}, {personData.address}
            </Field>

            <Field>
                <Name>Заказчик:</Name><strong>{values.guestName}</strong>
            </Field>

            <SummaryTable values={values} hotelName={hotelName}/>
            <br/>
            <Field italic>
                <Name>Всего оказано услуг на сумму:</Name>
                <div>
                    {values.totalPriceIntString} рублей 00 копеек.
                </div>
            </Field>
            <br/>
            <Field italic>
                Вышеперечисленные работы (услуги) выполнены полностью и в срок. Заказчик претензий по объему, качеству и
                срокам оказания услуг претензий не имеет.
            </Field>

            <br/>
            <Box>

                <Field width={"40%"}>
                    <Text caption="(место. печати).(подпись)">Исполнитель</Text>
                    <Text stretch/>
                    <Text>/Шавлохов Б.А/ </Text>
                </Field>
                <Field width={"40%"}>
                    <Name>Заказчик</Name>
                    <Text stretch caption=" ">
                    </Text>
                </Field>
            </Box>
        </Page>
    )
}
