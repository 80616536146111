import React, {useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import {breakfastsSelectors} from '../../Modules/breakfasts'
import {fullDate} from '../../Modules/formatters/date'
import history from '../../Modules/helpers/history'
import {SingleDatePicker} from '../common/DatePicker'
import {breakfastsBookedSelectors} from '../../Modules/breakfastsBooked'
import {FaRegCheckSquare} from "react-icons/fa";

const PageBlock = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 50px auto;
    padding: 30px;
    border-radius: 5px;
    position: relative;
`

const Message = styled.p`
    text-align: center;
    color: ${props => props.color ?? '#333'};
    font-size: ${props => props.size ?? '16px'};
`

const Link = styled.button`
    padding: unset;
    border: none;
    border-bottom: 1px dashed transparent;
    color: #2b62ff;
    background-color: transparent;
    outline: none;
`
const Flex = styled.p`
    display: flex;
    align-items: center;
    gap: 2px;
    margin-bottom: 10px;
`

const actionNameByAction = {
    sell: 'Продажа',
    refund: 'Возврат',
}

const breakfastSource = {
    web: 'Оплачено на сайте',
    reception: 'Оплачено на ресепшен',
    'pre-payed': 'Оплачено по безналу'
}


function createKey(action) {
    const {
        booking_number_uuid,
        action: type,
        breakfast,
        order_date,
        delivery_date,
    } = action
    const key = (Math.random() + 1).toString(36).substring(7)

    return `${booking_number_uuid}/${type}/${breakfast}/${order_date}/${delivery_date}_${key}`
}

export const Tr = styled.tr`
    background-color: ${props => props.canceled ? 'rgba(237, 20, 61, .12)' : 'transparent'};
`

const BreakfastAction = ({data, breakfastNameByPk}) => {
    const {
        booking_number_uuid,
        qty,
        action,
        breakfast,
        order_date,
        delivery_date,
        source,
        guest_name,
        room,
        canceled,
        is_complementary,
        note
    } = data

    return (
        <Tr canceled={canceled}>
            <td>{room || 'Не указана'}</td>
            <td>{guest_name}</td>
            <td>{actionNameByAction[action]}</td>
            <td>{breakfastNameByPk[breakfast]}</td>
            <td>{breakfastSource[source]}</td>
            <td>{qty}</td>
            <td>{fullDate(order_date)}</td>
            <td>{fullDate(delivery_date)}</td>
            <td>
                {is_complementary && <Flex><FaRegCheckSquare/>Комплиментарный</Flex>}
                {note}
            </td>
            <td>
                <Link
                    onClick={() => {
                        history.push(`/reservation?pk=${booking_number_uuid}`)
                    }}
                >
                    Перейти в бронь
                </Link>
            </td>
        </Tr>
    )
}

const Content = ({actions, loading}) => {
    const breakfastNameByPk = useSelector(breakfastsSelectors.namesByPk)

    if (loading) return <Message size="14px">Загрузка..</Message>
    if (!actions) return <Message size="14px">Данные не загружены</Message>
    if (actions.length === 0) return <Message size="14px">Нет данных</Message>

    return (
        <table className="breakfast-actions-table" style={{textAlign: 'center', marginBottom: '15px'}}>
            <thead>
            <tr>
                <th>Номер комнаты</th>
                <th>ФИО гостя</th>
                <th>Действие</th>
                <th>Завтрак</th>
                <th>Источник</th>
                <th>Количество</th>
                <th>Дата покупки</th>
                <th>Дата доставки</th>
                <th>Примечание</th>
                <th>Действия</th>
            </tr>
            </thead>
            <tbody>
            {actions.map(action => (
                <BreakfastAction
                    key={createKey(action)}
                    data={action}
                    breakfastNameByPk={breakfastNameByPk}
                />
            ))}
            </tbody>
        </table>
    )
}

export const BreakfastActions = () => {
    const [deliveryDate, setDeliveryDate] = useState(null)
    const [breakfastCount, setBreakfastCount] = useState(0)

    const actions = useSelector(breakfastsBookedSelectors.breakfastsIsNotGivenAwayNotCanceled)

    const filteredActions = useMemo(() => {
        if (!actions) return null

        let result = actions

        if (deliveryDate) {
            result = result.filter(action => action.delivery_date === deliveryDate)
        }

        setBreakfastCount(result.reduce((a, b) => a + b.qty, 0))
        return result
    }, [actions, deliveryDate])

    return (
        <PageBlock>
            <SingleDatePicker
                label="Дата доставки"
                value={deliveryDate}
                onChange={setDeliveryDate}
                isClearable={true}
                containerStyle={{margin: '0 auto 32px'}}
            />
            <Content actions={filteredActions}/>
            <Message>Общее количество: {breakfastCount} шт.</Message>
        </PageBlock>
    )
}
