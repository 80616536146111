import Cookies from 'js-cookie';
import * as types from './types'
import {getSavedSession} from "../../Components/DayTotals/helpers/CookiesAPI/changers";

function getInitialState() {
  const adminData = getSavedSession()
  const lcode = Cookies.getJSON(types.LCODE)
  if (adminData && lcode) {
    const { admin, adminID } = adminData
    const { lcode: num, name } = lcode

    return {
      admin,
      adminID,
      lcode: num,
      lcodeName: name,
      lcodeObj: lcode,
      isAuthorized: true,
      isLoading: false,
      error: '',
    }
  }

  return {
    admin: null,
    adminID: null,
    isAuthorized: false,
    isLoading: false,
    error: '',
    lcode: null,
    lcodeName: '',
    lcodeObj: null,
  }
}

export const reducer = (state = getInitialState(), action) => {
  const { payload } = action

  switch (action.type) {
    case types.LCODE:
      return {
        ...state,
        lcodeObj: payload,
        lcode: payload.lcode,
        lcodeName: payload.name,
      }

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        admin: payload.admin,
        adminID: payload.adminID,
        isAuthorized: true,
      }

    case types.LOGIN_FAILED:
      return state

    case types.LOGOUT:
      return {
        ...state,
        admin: null,
        adminID: null,
        isAuthorized: false,
        lcode: null,
        lcodeName: '',
        lcodeObj: null,
      }

    case types.LOADING_STARTED:
      return {
        ...state,
        isLoading: true,
      }

    case types.LOADING_ENDED:
      return {
        ...state,
        isLoading: false,
      }

    case types.ERROR_SET:
      return {
        ...state,
        error: payload.error,
      }

    case types.ERROR_CLEAR:
      return {
        ...state,
        error: '',
      }

    default:
      return state
  }
}
